import './index.scss';
import { Outlet } from'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
const Layout = () => {
    return (
        <div className='App'>
            <Navigation />
            <div>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}
export default Layout;