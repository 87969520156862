import { NavLink } from 'react-router-dom';
import './index.scss'
const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className='footer-inner'>
          <div className='footer-phoenix'>
            <NavLink className='footer-phoenix__header' exact="true" to="/">
              <img src={require('./src/phoenix.png')} alt="logo" />
              <h1>PHOENIX</h1>
            </NavLink>
            <p>The ultimate Discord bot for enhanced servers. With fun commands, powerful moderation tools, a comprehensive logging system, and AI integration, Phoenix has everything you need to create a fantastic server. Developed by Qmir © 2024.</p>
          </div>
          <div className='footer-links'>
            <h3>Explore More</h3>
            <NavLink className='footer-links__link' exact="true" to="/Privacy-Policy">
              <div className='footer-links__link__icon'></div>
              <p>Privacy Policy</p>
            </NavLink>
            <NavLink className='footer-links__link' exact="true" to="/Terms-Of-Service">
              <div className='footer-links__link__icon'></div>
              <p>Terms Of Service</p>
            </NavLink>
            <NavLink className='footer-links__link' exact="true" to="/Commands">
              <div className='footer-links__link__icon'></div>
              <p>Commands</p>
            </NavLink>
            <NavLink className='footer-links__link' exact="true" to="/Update-Notes">
              <div className='footer-links__link__icon'></div>
              <p>Update Notes</p>
            </NavLink>
          </div>
          <div className='footer-contact-me'>
            <h3>Contact me</h3>
            <div className='footer-contact-me__methods'>
              <div className='footer-contact-me__methods__inner'>
                <div className='footer-contact-me__methods__inner__icons'></div>
                <a href='mailto:qmirdev@gmail.com' rel="noreferrer" target='_blank'>qmirdev@gmail.com</a>
              </div>
              <div className='footer-contact-me__methods__inner'>
                <div className='footer-contact-me__methods__inner__icons'></div>
                <a href='https://github.com/qmirdev' rel="noreferrer" target='_blank'>Qmirdev</a>
              </div>
              <div className='footer-contact-me__methods__inner'>
                <div className='footer-contact-me__methods__inner__icons'></div>
                <a href='https://discord.com/users/654969698471116800' rel="noreferrer" target='_blank'>q31r</a>
              </div>
            </div>
          </div>
          <div className='footer-donate'>
            <div className='footer-donate__card'>
              <h3>Keep Phoenix fueled with your support! ♥️</h3>
              <p>Your donation helps me stay up and running and powers my creativity to add more cool features.</p>
              <h4>Dogecoin Wallet Address:</h4>
              <p>D7TS8SDZrxknHugeiJu19v9M99XpUbrp4z</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;