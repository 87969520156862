import { useEffect } from 'react';
import './index.scss';
const TermsOfService = () => {
    useEffect(() => {
        document.title = "Terms of Service | Phoenix";
        document.querySelector('meta[name="description"]')
              .setAttribute("content", "");
        document.querySelector('meta[name="keywords"]')
              .setAttribute("content", "Terms of Service");
        const canonicalLink = document.querySelector('link[rel="canonical"]');
              canonicalLink.href = 'https://phoenix.qmirdev.ir/Terms-Of-Service';
            //   og: , twitter:
        }, []);
    return (
        <>
            <div className='page-content'>
                <h1 className='dt' style={{textAlign: 'center'}}>Terms of Service</h1>
                <p className='dp'>These Terms of Service govern your access and use of the Phoenix Bot developed by Qmir. By inviting the Bot to your Discord server or using its features, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not use the Bot.</p>
                
                <h3 className='dh'>1. Commitment to User Privacy</h3>
                <p className='dp'>1.1 Phoenix Bot prioritizes user privacy. Unlike many other bots, we do not store any data you or your server members provide while using the Bot. This includes commands used, server information accessed through logging features, and any other interaction with the Bot.</p>
                <br/>
                <p className='dp'>1.2 Your data remains completely secure and vanishes after each use. You can leverage the Bot's functionalities with complete confidence that your privacy is never compromised.</p>
                
                <h3 className='dh'>2. Responsible Use</h3>
                <p className='dp'>These Terms of Service govern your access and use of the Phoenix Bot developed by Qmir. By inviting the Bot to your Discord server or using its features, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not use the Bot.</p>
                <p className='dp'>2.1 You must be at least the minimum age required to use Discord (currently 13) to use the Bot.</p>
                <p className='dp'>2.2 You are responsible for all activity that occurs under your Discord account while using the Bot.</p>
                <p className='dp'>2.3 You agree to use the Bot in a lawful and respectful manner. You will not use the Bot to:</p>
                <p className='dp'>
                    <ul>
                        <li>* Spam, harass, or bully other users.</li>
                        <li>* Promote violence, hate speech, or discrimination.</li>
                        <li>* Violate the privacy of others.</li>
                        <li>* Cheat, exploit bugs, or gain an unfair advantage in games or other applications.</li>
                        <li>* Damage or disrupt the Bot or any other Discord server.</li>
                        <li>* Violate any applicable laws or regulations.</li>
                    </ul>
                </p>
                <p className='dp'>2.4 Server owners are responsible for ensuring their members comply with these Terms while using the Bot on their server. The Developer reserves the right to take action against servers that violate these Terms, including disabling the Bot on those servers.</p>
                
                <h3 className='dh'>3. Functionality Overview</h3>
                <p className='dp'>3.1 Phoenix Bot offers a comprehensive suite of features designed to enhance your Discord server experience. These functionalities include, but are not limited to:</p>
                <pre>
                    <code>
                        * <strong>Moderation Tools:</strong> Effectively manage your server with features like member banning, kicking, muting.<br/>
                        * <strong>Fun Commands:</strong>  Engage your server members with a variety of entertaining and interactive commands.<br/>
                        * <strong>Logging System:</strong> Gain valuable insights into server activity with detailed logs accessible to server owners. (Please note: Logs do not contain any user data due to our commitment to privacy.)<br/>
                        * <strong>And More!</strong> Phoenix Bot is constantly evolving, with new features and improvements added regularly.
                    </code>
                </pre>
                
                <h3 className='dh'>4. Disclaimer</h3>
                <p className='dp'>4.1 The Bot is provided "as is" and without warranty of any kind, express or implied. The Developer disclaims all warranties, including but not limited to, the warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                <p className='dp'>4.2 The Developer does not warrant that the Bot will be uninterrupted, error-free.</p>
                <p className='dp'>4.3 The Developer is not responsible for any loss or damage caused by your use of the Bot, including any potential disruptions caused by the Bot itself or third-party interference.</p>
                
                <h3 className='dh'>5. Limitations of Liability</h3>
                <p className='dp'>5.1 To the extent permitted by law, the Developer shall not be liable for any damages arising out of or related to your use of the Bot, including but not limited to, direct, indirect, incidental, consequential, or punitive damages.</p>
                
                <h3 className='dh'>6. Intellectual Property</h3>
                <p className='dp'>6.1 The underlying code, design, and concept of the Phoenix Bot are the property of the Developer.</p>
                <p className='dp'>6.2 You are granted a non-exclusive, non-transferable license to use the Bot in accordance with these Terms.</p>

                <h3 className='dh'>7. Modifications to the Terms</h3>
                <p className='dp'>7.1 The Developer may modify these Terms at any time by posting the revised Terms on the Phoenix Bot website.</p>
                <p className='dp'>7.2 You agree to be bound by the revised Terms by continuing to use the Bot after the revised Terms are posted.</p>

                <h3 className='dh'>8. Termination</h3>
                <p className='dp'>8.1 These Terms may be terminated by either party at any time.</p>
                <p className='dp'>8.2 The Developer reserves the right to terminate your access to the Bot for any reason, with or without notice, if you violate these Terms.</p>
                <p className='dp'>8.3 Upon termination, your right to use the Bot will cease immediately.</p>

                <h3 className='dh'>9. Governing Law</h3>
                <p className='dp'>9.1 These Terms shall be governed by and construed in accordance with the laws of United States.</p>

                <h3 className='dh'>10. Dispute Resolution</h3>
                <p className='dp'>10.1 Any dispute arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association.</p>

                <h3 className='dh'>11. Entire Agreement</h3>
                <p className='dp'>11.1 These Terms constitute the entire agreement between you and the Developer regarding your use of the Bot.</p>

                <h3 className='dh'>11. Contact Us</h3>
                <p className='dp'>11.1 If you have any questions about these Terms, please contact the Developer at:</p>
                <p className='dp'>
                    <div className='footer-contact-me__methods'>
                        <div className='footer-contact-me__methods__inner'>
                            <div className='footer-contact-me__methods__inner__icons'></div>
                            <a href='mailto:qmirdev@gmail.com' rel="noreferrer" target='_blank'>qmirdev@gmail.com</a>
                        </div>
                        <div className='footer-contact-me__methods__inner'>
                            <div className='footer-contact-me__methods__inner__icons'></div>
                            <a href='https://github.com/qmirdev' rel="noreferrer" target='_blank'>Qmirdev</a>
                        </div>
                        <div className='footer-contact-me__methods__inner'>
                            <div className='footer-contact-me__methods__inner__icons'></div>
                            <a href='https://discord.com/users/654969698471116800' rel="noreferrer" target='_blank'>q31r</a>
                        </div>
                    </div>
                </p>

                <h2 className='dt'>Thank you for using Phoenix Bot!</h2>
            </div>
        </>
    )
}
export default TermsOfService;