import { useEffect } from 'react';
import './index.scss';
const Commands = () => {
    useEffect(() => {
        document.title = "Commands | Phoenix";
        document.querySelector('meta[name="description"]')
              .setAttribute("content", "");
        document.querySelector('meta[name="keywords"]')
              .setAttribute("content", "");
        const canonicalLink = document.querySelector('link[rel="canonical"]');
              canonicalLink.href = 'https://phoenix.qmirdev.ir/Commands';
            //   og: , twitter:
        }, []);
    return (
        <>
            <div className='page-content'>
                <h1 className='dt' style={{textAlign:'center'}}>Commands</h1>
            </div>
        </>
    )
}
export default Commands;