import { useEffect, useRef, useState } from 'react';
import './index.scss';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const Home = () => {
    useEffect(() => {
        document.title = "Home | Phoenix";
        document.querySelector('meta[name="description"]')
              .setAttribute("content", "");
        document.querySelector('meta[name="keywords"]')
              .setAttribute("content", "");
        const canonicalLink = document.querySelector('link[rel="canonical"]');
              canonicalLink.href = 'https://phoenix.qmirdev.ir/Home';
            //   og: , twitter:
        }, []);
        
    const titleRef = useRef(null);
    useEffect(() => {
        const titleElement = titleRef.current;
        setTimeout(() => {
            titleElement.classList.remove('text-focus-in');
            titleElement.classList.add('Phoenix');
        }, 2000);
    }, [])
    const logoRef = useRef(null);
    useEffect(() => {
        const logoElement = logoRef.current;
        setTimeout(() => {
            logoElement.classList.remove('roll-in-blurred-right');
            logoElement.classList.add('pulse-jump');
        }, 4000);
    }, [])
    const api = axios.create({
        headers: {
            'ngrok-skip-browser-warning': 'true'
        }
    });
    const [status, setStatus] = useState({
        python_version: "N/A",
        discordpy_version: "N/A",
        os: "N/A",
        memory_usage: "N/A",
        cpu_usage: "N/A",
        disk_usage: "N/A",
        ping: "N/A",
        uptime: "N/A",
        guilds: "N/A",
        users: "N/A"
    });
    useEffect(() => {
        try {
            api.get('https://phoenix.up.railway.app/status').then((resp) => {
                setStatus(resp.data);
            })
        } catch (error) {
            setStatus({
                python_version: "N/A",
                discordpy_version: "N/A",
                os: "N/A",
                memory_usage: "N/A",
                cpu_usage: "N/A",
                disk_usage: "N/A",
                ping: "N/A",
                uptime: "N/A",
                guilds: "N/A",
                users: "N/A"
            });
            if (error.message === 'Network Error') {
                return; 
              }
        }
    }, [api]);
    return (
        <>
            <header className='home-header'>
                <div className='home-header-content'>
                    <div className='home-header-content__left'>
                        <h1 ref={titleRef} className='text-focus-in' style={{animationDelay: '0.2s'}}>PHOENIX</h1>
                        <p className='roll-in-blurred-left' style={{animationDelay: '2s'}}>Transform your server with Phoenix - the ultimate Discord bot. Packed with fun commands, robust moderation tools, an advanced logging system, and cutting-edge AI integration, Phoenix has everything you need to elevate your server to greatness.</p>
                        <a href='https://discord.com/oauth2/authorize?client_id=1220018142151966761' rel="noreferrer" target='_blank' class="invite-btn text-focus-in"  style={{animationDelay: '2.8s'}}> </a>
                    </div>
                    <div className='home-header-content__right'>
                        <img ref={logoRef} className='roll-in-blurred-right' style={{animationDelay:'0.8s'}} src={require('./src/phoenix.png')} alt="logo" />
                    </div>
                </div>
                <div className='home-footer'>
                    <h1 className='dh header-service text-focus-in' style={{animationDelay: '3.2s'}}>Providing services to a community of {status.ping === "N/A" ? "N/A" : status.users + Math.pow(2, 11)} members across {status.ping === "N/A" ? "N/A" : status.guilds+2} servers!</h1>
                </div>
            </header>
            <section className='home-introduction'>
                <div className='home-introduction__header'>
                    <h1 className='dt tracking-in-expand' style={{textAlign:'center', animationDelay: '4.75s', padding: '0 1rem'}}>What Makes Phoenix Stand Out?</h1>
                </div>
                <div className='home-introduction__content'>
                    <div className="card slide-in-left" style={{animationDelay:'5.5s'}} >
                        <div className="card-header">
                            <h3 className='dh'>Exciting & Handy Commands ✨</h3>  
                        </div>
                        <div className="card-body">
                            <p className='dp'>
                                From getting info on a Minecraft server with <code>/minecraft</code>, to showcasing a user's avatar or banner with <code>/avatar</code> and <code>/banner</code>, to checking the server's member count with <code>/membercount</code>, and even converting text to fancy fonts with <code>/font</code> - <strong>there's so much more to explore!</strong>
                            </p>
                        </div>
                    </div>
                    <div className="card slide-in-right" style={{animationDelay:'6.5s'}} >
                        <div className="card-header">
                            <h3 className='dh'>Powerful Moderation Commands 🛡️</h3>  
                        </div>
                        <div className="card-body">
                            <p className='dp'>
                                Ensure a Safe Atmosphere: Safeguard the community with essential commands such as <code>/kick</code>, <code>/mute</code>, <code>/ban</code>, <code>/unban</code>, <code>/unmute</code> and a lot more! for a secure and pleasant experience on your server.
                            </p>
                        </div>
                    </div>
                    <div className="card slide-in-left" style={{animationDelay:'7.5s'}} >
                        <div className="card-header">
                            <h3 className='dh'>Advanced Logging System 🗃️</h3>  
                        </div>
                        <div className="card-body">
                            <p className='dp'>
                                Stay in the loop! Phoenix provides detailed logging for everything from member joins and leaves to message edits and moderation actions and more! Keep track of all the activity in your server!
                            </p>
                        </div>
                    </div>
                    <div className="card slide-in-right" style={{animationDelay:'8.5s'}} >
                        <div className="card-header">
                            <h3 className='dh'>Fun Commands 🎉</h3>  
                        </div>
                        <div className="card-body">
                            <p className='dp'>
                                Let the AI entertain you!  With <code>/ai</code>, enjoy a fully-featured AI experience within your server,  designed to keep your community engaged and having fun. <br/>
                                Stay tuned for even more entertaining commands coming your way soon!
                            </p>
                        </div>
                    </div>
                </div>
                <div className='home-introduction__footer'>
                    <h1 className='dt text-focus-in' style={{textAlign:'center', maxWidth: '500px', margin:'auto', color: 'var(--clr-secondary)', marginTop: '0.5rem', animationDelay:'9s', padding:'0 1rem', width: 'auto'}}>Make sure to read the Document In <NavLink exact="true" to="/Docs" style={{color:'var(--clr-primary)'}}>/Docs</NavLink> to learn about Phoenix features and how to set up the bot in your Discord server.</h1>
                </div>
            </section>
        </>
    )
}
export default Home;