import { Routes, Route, BrowserRouter } from'react-router-dom';
import './App.scss';
import Layout from './Components/Layout';
import Home from './Pages/Home';
import Docs from './Pages/Docs';
import Status from './Pages/Status';
import PrivacyPolicy from './Pages/Privacy-Policy';
import TermsOfService from './Pages/Terms-Of-Service';
import Commands from './Pages/Commands';
import UpdateNotes from './Pages/Update-Notes';

function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} />
            <Route path="Docs" element={<Docs />} />
            <Route path="Status" element={<Status />} />
            <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
            <Route path="Terms-Of-Service" element={<TermsOfService />} />
            <Route path="/Commands" element={<Commands />} />
            <Route path="/Update-Notes" element={<UpdateNotes />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

export default App;