import { NavLink } from 'react-router-dom';
import './index.scss'
import { useState } from 'react';
const Navigation = () => {
  const [isSlideNavOpen, setIsSlideNavOpen] = useState(false);
  const handleSlideNavToggle = () => {
    setIsSlideNavOpen(!isSlideNavOpen);
  }
  return (
    <>
      <div className='navigation-container'>
        <div className="navigation-inner">
          <div className="navigation-container__left">
            <div className="navigation-container__left__logo">
              <NavLink exact="true" to="/" style={{ display:'flex', justifyContent:'center', alignItems:'center', gap:'0.5rem' }}>
                <img src={require('./src/phoenix.png')} alt="logo" />
                <h1>PHOENIX</h1>
              </NavLink>
            </div>
            <div className="navigation-container__left__links">
              <NavLink className='navigation-container__left_links__link' exact="true" to="/">
                  <div className='navigation-container__left_links__link__icon'></div>
                  <p>Home</p>
              </NavLink>
              <NavLink className='navigation-container__left_links__link' exact="true" to="/Status">
                  <div className='navigation-container__left_links__link__icon'></div>
                  <p>Status</p>
              </NavLink>
              <NavLink className='navigation-container__left_links__link' exact="true" to="/Docs">
                  <div className='navigation-container__left_links__link__icon'></div>
                  <p>Docs</p>
              </NavLink>
              <a className='navigation-container__left_links__link' rel="noreferrer" href='https://discord.com/users/654969698471116800' target='_blank'>
                  <div className='navigation-container__left_links__link__icon'></div>
                  <p>Support</p>
              </a>
            </div>
          </div>
          <div className="navigation-container__right">
            <div className="navigation-container__right__items">
              <a href='https://discord.com/oauth2/authorize?client_id=1220018142151966761' rel="noreferrer" target='_blank'>
                  <p>Invite To Discord</p>
              </a>
              <div>
                <label htmlFor="slidenavtoggle" style={{ display: 'none' }}>toggle</label>
                <input type="checkbox" name="slidenavtoggle" id="slidenavtoggle" onChange={handleSlideNavToggle}  />
                <div className="slidenavtogglebars-container">
                  <div className="slidenavtogglebars">
                    <span className="slidenavbar1"></span>
                    <span className="slidenavbar2"></span>
                    <span className="slidenavbar3"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul id="slidenavmenu" style={{
            height: isSlideNavOpen ? '15rem' : '0'
          }}>
          <li className="slide-nav-item">
            <NavLink exact="true" to="/" className="slide-nav-item__link">
              <div className='slide-nav-item__link__icon'></div>
              <p>Home</p>
            </NavLink>
          </li>
          <li className="slide-nav-item">
            <NavLink exact="true" to="/Docs" className="slide-nav-item__link">
              <div className='slide-nav-item__link__icon'></div>
              <p>Docs</p>
            </NavLink>  
          </li>
          <li className="slide-nav-item">
            <NavLink exact="true" to="/Status" className="slide-nav-item__link">
              <div className='slide-nav-item__link__icon'></div>
              <p>Status</p>
            </NavLink>
          </li>
          <li className="slide-nav-item">
            <a href='https://discord.com/users/654969698471116800' rel="noreferrer" target='_blank' className="slide-nav-item__link">
              <div className='slide-nav-item__link__icon'></div>
              <p>Support</p>
            </a>
          </li>
          <li className="slide-nav-item slide-nav-item__invdc">
            <a href='https://discord.com/oauth2/authorize?client_id=1220018142151966761' rel="noreferrer" target='_blank'>
              <p>Invite To Discord</p>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Navigation;