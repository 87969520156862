import { useEffect } from 'react';
import './index.scss';
const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Privacy Policy | Phoenix";
        document.querySelector('meta[name="description"]')
              .setAttribute("content", "");
        document.querySelector('meta[name="keywords"]')
              .setAttribute("content", "Privacy Policy");
        const canonicalLink = document.querySelector('link[rel="canonical"]');
              canonicalLink.href = 'https://phoenix.qmirdev.ir/Privacy-Policy';
            //   og: , twitter:
        }, []);
    return (
        <>
            <div className='page-content'>
                <h1 className='dt' style={{textAlign: 'center'}}>Privacy Policy</h1>

                <h3 className='dh'>Protecting Your Privacy</h3>
                <p className='dp'>At Phoenix Bot, developed by Qmir, we understand the importance of user privacy. This Privacy Policy outlines how we handle any information collected or accessed while you use the Bot.</p>
                
                <h3 className='dh'>Transparency and User Control</h3>
                <p className='dp'><strong >Commitment to No Data Storage:</strong> Unlike many bots, Phoenix Bot prioritizes complete user privacy. We do not store any data generated through your interaction with the Bot. This includes:</p>
                <p className='dp'>1. Commands you use.</p>
                <p className='dp'>2. Content processed through commands (e.g., text in commands).</p>
                <p className='dp'>3. Server information accessed by server owners through logging features (content itself is not stored).</p>
                <p className='dp'>4. Any other interaction you have with the Bot.</p>
                <p className='dp'><strong>Ephemeral Data Processing:</strong> Any data the Bot temporarily processes to perform its functions vanishes after each use. This ensures your information remains completely secure and never leaves your Discord server.</p>

                <h3 className='dh'>What You Need to Know About Your Data</h3>
                <p className='dp'>Phoenix Bot does not collect or store any of the following:</p>
                <p className='dp'>1. Personally Identifiable Information (PII) such as usernames, email addresses, IP addresses, or Discord user IDs.</p>
                <p className='dp'>2. Server member information accessed through logging features (content itself is not stored).</p>
                <p className='dp'>3. Content generated through the use of the Bot's commands.</p>
                
                <h3 className='dh'>Third-Party Integrations</h3>
                <p className='dp'>While Phoenix Bot itself does not collect any data, some commands may interact with third-party services.  These third-party services may have their own privacy policies, which we recommend you review. The Developer is not responsible for the privacy practices of any third-party services.</p>

                <h3 className='dh'>Security Measures</h3>
                <p className='dp'>The Developer implements industry-standard security practices to protect against unauthorized access to Discord's API or any temporary data processed by the Bot. However, due to the inherent nature of online interactions, we cannot guarantee absolute security.</p>

                <h3 className='dh'>Changes to this Privacy Policy</h3>
                <p className='dp'>The Developer reserves the right to update this Privacy Policy at any time. We will notify users of any significant changes by posting the revised Privacy Policy on the Phoenix Bot website.</p>
                
                <h3 className='dh'>Contact Us</h3>
                <p className='dp'>If you have any questions regarding this Privacy Policy, please feel free to contact the Developer at:<br/></p>
                <p className='dp'>
                    <div className='footer-contact-me__methods'>
                        <div className='footer-contact-me__methods__inner'>
                            <div className='footer-contact-me__methods__inner__icons'></div>
                            <a href='mailto:qmirdev@gmail.com' rel="noreferrer" target='_blank'>qmirdev@gmail.com</a>
                        </div>
                        <div className='footer-contact-me__methods__inner'>
                            <div className='footer-contact-me__methods__inner__icons'></div>
                            <a href='https://github.com/qmirdev' rel="noreferrer" target='_blank'>Qmirdev</a>
                        </div>
                        <div className='footer-contact-me__methods__inner'>
                            <div className='footer-contact-me__methods__inner__icons'></div>
                            <a href='https://discord.com/users/654969698471116800' rel="noreferrer" target='_blank'>q31r</a>
                        </div>
                    </div>
                </p>
                <p className='dp'>We appreciate your trust in Phoenix Bot and hope this Privacy Policy demonstrates our commitment to user privacy.</p>

                <h2 className='dt'>Thank you for using Phoenix Bot!</h2>
            </div>
        </>
    )
}
export default PrivacyPolicy;