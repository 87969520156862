import { useEffect, useState } from 'react';
import './index.scss';
import axios from 'axios';

const Status = () => {
    useEffect(() => {
        document.title = "Status | Phoenix";
        document.querySelector('meta[name="description"]')
              .setAttribute("content", "");
        document.querySelector('meta[name="keywords"]')
              .setAttribute("content", "");
        const canonicalLink = document.querySelector('link[rel="canonical"]');
              canonicalLink.href = 'https://phoenix.qmirdev.ir/Status';
            //   og: , twitter:
        }, []);
    const api = axios.create({
        headers: {
            'ngrok-skip-browser-warning': 'true'
        }
    });
    const [status, setStatus] = useState({
        python_version: "N/A",
        discordpy_version: "N/A",
        os: "N/A",
        memory_usage: "N/A",
        cpu_usage: "N/A",
        disk_usage: "N/A",
        ping: "N/A",
        uptime: "N/A",
        guilds: "N/A",
        users: "N/A"
    });
    useEffect(() => {
        try {
            api.get('https://phoenix.up.railway.app/status').then((resp) => {
                setStatus(resp.data);
            })
        } catch (error) {
            setStatus({
                python_version: "N/A",
                discordpy_version: "N/A",
                os: "N/A",
                memory_usage: "N/A",
                cpu_usage: "N/A",
                disk_usage: "N/A",
                ping: "N/A",
                uptime: "N/A",
                guilds: "N/A",
                users: "N/A"
            });
            if (error.message === 'Network Error') {
                return; 
              }
        }
    }, [api]);
    return (
        <>
            <div className='page-content'>
                <h1 className='dt' style={{textAlign:'center'}}>Service Status</h1>
            </div>
            <div className='status-box'>
                <div className='status-box-header'>
                    <h3>Current Status by service</h3>
                    <div className='status-box-header-icons'>
                        <div>
                            <div className="noissuesico"></div>
                            <p>No Issues</p>
                        </div>
                        <div>
                            <div className="incidentico"></div>
                            <p>incident</p>
                        </div>
                        <div>
                            <div className="outageico"></div>
                            <p>Outage</p>
                        </div>
                    </div>
                </div>
                <hr className='hr'></hr>
                <div className='status-box-body'>
                    <div>
                        <h3>
                            Status
                        </h3>
                        <p className={status.ping === "N/A" ? "statusoffline" : "statusonline"}>
                            {status.ping === "N/A" ? "Offline" : "Online"}
                        </p>
                    </div>
                    <div>
                        <h3>
                            Ping
                        </h3>
                        <p 
                            className={
                            status.ping === "N/A"
                                ? ""
                                : status.ping >= 1 && status.ping <= 150
                                ? "pinglow"  
                                : status.ping >= 151 && status.ping <= 300
                                    ? "pingmid"
                                    : "pinghigh"
                            }
                        >
                            {status.ping === "N/A" ? "N/A" : status.ping + " ms" }
                        </p>
                    </div>
                    <div>
                        <h3>
                            uptime
                        </h3>
                        <p>
                            {status.ping === "N/A" ? "N/A" : status.uptime}
                        </p>
                    </div>
                    <hr className='hr' style={{margin:'0'}}/>
                    <div className='server-customers'>
                        <div>
                            <h3>Servers</h3>
                            <p>{status.ping === "N/A" ? "N/A" : status.guilds}</p>
                        </div>
                        <div>
                            <h3>Users</h3>
                            <p>{status.ping === "N/A" ? "N/A" : status.users}</p> 
                        </div>
                    </div>
                    <hr className='hr' style={{margin:'0'}}/>


                    <div className='server-usage'>
                        <div className='server-usage-item'>
                            <h3>Memory Usage</h3>

                            <div className='server-usage-pb-container'>
                                <div className={`server-usage-pb ${
                                    status.memory_usage >= 0 && status.memory_usage <= 75
                                        ? 'server-usage-pblow'
                                        : status.memory_usage >= 76 && status.memory_usage <= 90
                                        ? 'server-usage-pbmid'
                                        : 'server-usage-pbhigh'
                                    }`}
                                    style={{ width: `${status.memory_usage}%` }}
                                ></div>
                                <p>{status.memory_usage}%</p>
                            </div>
                        </div>

                        <div className='server-usage-item'>
                            <h3>CPU Usage</h3>

                            <div className='server-usage-pb-container'>
                                <div className={`server-usage-pb ${
                                    status.cpu_usage >= 0 && status.cpu_usage <= 75
                                        ? 'server-usage-pblow'
                                        : status.cpu_usage >= 76 && status.cpu_usage <= 90
                                        ? 'server-usage-pbmid'
                                        : 'server-usage-pbhigh'
                                    }`}
                                    style={{ width: `${status.cpu_usage}%` }}
                                ></div>
                                <p>{status.cpu_usage}%</p>
                            </div>
                        </div>

                        <div className='server-usage-item'>
                            <h3>Disk Usage</h3>

                            <div className='server-usage-pb-container'>
                                <div className={`server-usage-pb ${
                                    status.disk_usage >= 0 && status.disk_usage <= 75
                                        ? 'server-usage-pblow'
                                        : status.disk_usage >= 76 && status.disk_usage <= 90
                                        ? 'server-usage-pbmid'
                                        : 'server-usage-pbhigh'
                                    }`}
                                    style={{ width: `${status.disk_usage}%` }}
                                ></div>
                                <p>{status.disk_usage}%</p>
                            </div>
                        </div>
                    </div>


                    <div>
                        <h3>
                            OS Info
                        </h3>
                        <p>
                            {status.ping === "N/A" ? "N/A" : status.os }
                        </p>
                    </div>
                    <div>
                        <h3>
                            Python Version
                        </h3>
                        <p>
                            {status.ping === "N/A" ? "N/A" : status.python_version }
                        </p>
                    </div>
                    <div>
                        <h3>
                            Discord.py Version
                        </h3>
                        <p>
                            {status.ping === "N/A" ? "N/A" : status.discordpy_version }
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Status;