import { useEffect } from 'react';
import './index.scss';
const UpdateNotes = () => {
    useEffect(() => {
        document.title = "Update Notes | Phoenix";
        document.querySelector('meta[name="description"]')
              .setAttribute("content", "");
        document.querySelector('meta[name="keywords"]')
              .setAttribute("content", "");
        const canonicalLink = document.querySelector('link[rel="canonical"]');
              canonicalLink.href = 'https://phoenix.qmirdev.ir/Update-Notes';
            //   og: , twitter:
        }, []);
    return (
        <>
            <div className='page-content'>
                <h1 className='dt' style={{textAlign:'center'}}>Update Notes</h1>
            </div>
        </>
    )
}
export default UpdateNotes;